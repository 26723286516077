<template>
  <b-container fluid="true">
    <!-- Reports Interface controls -->
    <h5 class="my-3"><strong>REPORTS BY FEED</strong></h5>
    <a-row :gutter="[16,4]">
      <a-col :xl="8">
        <a-select
          mode="multiple"
          allowClear
          placeholder="Publishers"
          style="width: 100%"
          :showSearch="true"
          :filterOption="true"
          optionFilterProp="children"
          v-model="filteredColumns.selectedPublishers.Values"
          >
          <a-select-option v-for="obj in XMLpublisher.list" :key="obj.Id">[{{obj.Id}}] {{obj.Name}}</a-select-option>
          </a-select>
      </a-col>
      <a-col :xl="8">
        <a-select
          mode="multiple"
          allowClear
          placeholder="Feeds"
          style="width: 100%"
          :showSearch="true"
          :filterOption="true"
          optionFilterProp="children"
          v-model="filteredColumns.selectedPubfeeds.Values"
        >
          <a-select-option v-for="obj in pubfeed.list" :key="obj.Id">[{{obj.Id}}] {{obj.Name}}</a-select-option>
        </a-select>
      </a-col>
      <a-col :xl="8">
        <a-select
          mode="multiple"
          allowClear
          placeholder="Countries"
          style="width: 100%"
          :showSearch="true"
          :filterOption="countryFilter"
          optionFilterProp="children"
          v-model="filteredColumns.selectedCountries.Values"
        >
          <a-select-option v-for="obj in info.countries" :key="obj.CC"><img :src="matchIcon(countries, obj.CC)" /> {{obj.Country}}</a-select-option>
        </a-select>
      </a-col>
    </a-row>
    <a-row :gutter="[16,4]">
      <a-col :xl="8">
        <a-select
          mode="multiple"
          allowClear
          placeholder="Operating Systems"
          style="width: 100%"
          :showSearch="true"
          :filterOption="true"
          optionFilterProp="children"
          v-model="filteredColumns.selectedOs.Values"
        >
          <a-select-option v-for="obj in info.oss" :key="obj.Family" >{{obj.Family}}</a-select-option>
        </a-select>
      </a-col>
      <a-col :xl="8">
        <a-select
          mode="multiple"
          allowClear
          placeholder="Browsers"
          style="width: 100%"
          :showSearch="true"
          :filterOption="true"
          optionFilterProp="children"
          v-model="filteredColumns.selectedBrowsers.Values"
        >
          <a-select-option v-for="obj in info.browsers" :key="obj.Family" >{{obj.Family}}</a-select-option>
        </a-select>
      </a-col>
      <a-col :xl="8">
        <div style="display: flex; justify-content: space-between">
          <a-range-picker
            :defaultValue="this.ranges['This Month']"
            @change="setDateRange"
            :ranges="ranges"
            style="width: 70%"
          />
          <a-button type="primary" class="ml-2" @click="setFilters" style="width: 30%">
            Apply
          </a-button>
          <b-button size="sm" class="btn-info mr-1" @click="exportToCsv" title="Export to CSV" style="margin-left: 6px">
            <i class="fa fa-file-excel-o"></i>
          </b-button>
        </div>
      </a-col>
    </a-row>
      <b-row class="mt-2">
      <b-col md="6" class="my-1">
        <b-form-group label-cols-sm="2" label="Per page:" class="mb-0">
          <perPageSelect v-model="perPage" :storageKey="'xml-reports-feed'" :pageOptions="pageOptions" />
          <!-- <b-form-select v-model="perPage" :options="pageOptions" class="form-control ml-0 w-25"></b-form-select> -->
          <columnSelect :storageKey="'xml-reports-feed-columns'" :options="fields"></columnSelect>
        </b-form-group>
      </b-col>

      <!--<b-col md="6" class="my-1 d-flex flex-row-reverse">
        <b-form-group class="mb-0">
          <b-input-group>
            <b-form-input v-model="filter" class="form-control w-50" placeholder="Type to Search"></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>-->
    </b-row>

    <!-- Main table element -->
    <b-table
      @sort-changed="sortingChanged"
      ref="table"
      show-empty
      :small="true"
      :bordered="true"
      :responsive="true"
      :striped="true"
      :hover="true"
      stacked="md"
      :items="getTableData"
      :fields="filteredFields"
      :current-page="currentPage"
      :per-page="perPage"
      :no-local-sorting="true"
      :sort-direction="sortDirection"
      @filtered="onFiltered"
      :busy="isBusy"
    >
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle" variant="primary"></b-spinner>
        </div>
      </template>
      <template #cell(CC)="data">
        <div><img :src="matchIcon(countries, data.item.CC)" /> {{ data.item.CC }}</div>
      </template>
      <template v-if="!loading" #custom-foot>
        <b-tr>
          <b-td v-for="col in filteredFields" :key="col.key">
            <div v-if="col.summed">
              <p v-if="col.formatter=== '$formatNumber'" class="text-right mb-0 font-weight-bold">
                {{ Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(sum[col.key]) }}
              </p>
              <p v-else class="text-right mb-0 font-weight-bold">
                {{ Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }).format(sum[col.key]) }}
              </p>
            </div>
          </b-td>
        </b-tr>
      </template>
    </b-table>
    <b-row>
      <b-col md="6" class="my-1">
        <p>Showing {{ ((currentPage - 1) * perPage).toLocaleString() }} to {{
            (((currentPage - 1) * perPage) + perPage > totalRows ? totalRows : ((currentPage - 1) * perPage) + perPage).toLocaleString()
          }} of {{ parseInt(totalRows).toLocaleString() }} entries</p>
      </b-col>
      <b-col md="6" class="my-1 d-flex flex-row-reverse">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          class="my-0"
        ></b-pagination>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import columnSelect from '@/components/custom/tableColumnSelect'
import perPageSelect from '@/components/custom/PerPageSelect'
import { getData, exportData } from '../../../api/xml/reports_pubfeed'
import { mapState } from 'vuex'
import pubfeedColumns from './columns/pubfeedFields'
import moment from 'moment'
import staticCountryData from '@/resources/countries.json'

export default {
  name: 'reports',
  components: { columnSelect, perPageSelect },
  data() {
    return {
      sum: {},
      sortDirection: 'desc',
      countries: staticCountryData,
      ranges: {
        Today: [moment(), moment()],
        Yesterday: [moment().subtract(1, 'day'), moment().subtract(1, 'day')],
        'This Week': [moment().startOf('isoWeek'), moment()],
        'Last Week': [moment().subtract(1, 'week').startOf('isoWeek'),
          moment().subtract(1, 'week').endOf('isoWeek')],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'),
          moment().subtract(1, 'month').endOf('month')],
      },
      dateRange: {
        Column: 'DateStats',
        From: '',
        To: '',
        Values: [],
      },
      filteredColumns: {
        selectedPublishers: {
          Column: 'PublisherId',
          Values: [],
        },
        selectedPubfeeds: {
          Column: 'PubfeedId',
          Values: [],
        },
        selectedCountries: {
          Column: 'CC',
          Values: [],
        },
        selectedOs: {
          Column: 'OsFamily',
          Values: [],
        },
        selectedBrowsers: {
          Column: 'BrowserFamily',
          Values: [],
        },
      },
      loading: false,
      isBusy: false,
      tableData: [],
      fields: pubfeedColumns,
      filters: [],
      currentPage: 1,
      perPage: 50,
      pageOptions: [20, 50, 100, 200],
      order: [],
      total: 0,
    }
  },
  created() {
    this.$store.dispatch('pubfeed/LOAD_PUBFEEDS')
    this.$store.dispatch('XMLpublisher/LOAD_PUBLISHERS')
    this.$store.dispatch('info/LOAD_COUNTRIES')
    this.$store.dispatch('info/LOAD_OSS')
    this.$store.dispatch('info/LOAD_BROWSERS')
    this.setDateRange(this.ranges['This Month'])
  },
  computed: {
    filteredFields() {
      return this.fields.filter(c => c.show)
    },
    totalRows() {
      return this.total
    },
    ...mapState(['XMLpublisher', 'pubfeed', 'info']),
  },
  methods: {
    exportToCsv() {
      const columns = this.fields.filter(ele => ele.show).map(ele => ele.key)
      exportData(columns, this.filters, this.order, this.currentPage, this.perPage).then(response => {
        const blob = new Blob([response], { type: 'text/csv' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = 'reports_by_feed_' + moment().format('YYYY-MM-DD') + '.csv'
        link.click()
        URL.revokeObjectURL(link.href)
      })
    },
    countryFilter(inputValue, option) {
      return option.componentOptions.children[1].text.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
    },
    matchIcon(array, code) {
      const cc = array.find(element => element.cc === code)
      if (cc === undefined) { return false } else {
        const image = cc.image
        return image
      }
    },
    setFilters() {
      const array = []
      for (const col in this.filteredColumns) {
        if (this.filteredColumns[col].Values.length !== 0) {
          array.push(this.filteredColumns[col])
        }
      }
      if (this.dateRange.From !== '' && this.dateRange.To !== '') {
        array.push(this.dateRange)
      }
      this.filters = array
      this.$refs.table.refresh()
    },
    setDateRange(date) {
      this.filters = this.filters.filter(ele => ele.Column !== 'Date')
      this.dateRange.From = date[0] ? date[0].format('YYYY-MM-DD') : ''
      this.dateRange.To = date[1] ? date[1].format('YYYY-MM-DD') : ''
      this.filters.push(this.dateRange)
    },
    sortingChanged(ctx) {
      this.order = []
      this.order.push({
        Column: ctx.sortBy,
        Ord: ctx.sortDesc ? 'DESC' : 'ASC',
      })
      // this.$refs.table.refresh()
    },
    getTableData(ctx, callback) {
      this.loading = true
      const columns = this.fields.filter(ele => ele.show).map(ele => ele.key)
      getData(this.currentPage, this.perPage, columns, this.filters, this.order).then(response => {
        this.tableData = response.Data
        this.sum = response.Sum
        this.total = response.Total
        this.loading = false
        callback(response.Data)
      })
      return null
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    callback(value) {
      return value
    },
  },
  watch: {
    fields: {
      handler(val) {
        this.$refs.table.refresh()
      },
      deep: true,
    },
  },
}
</script>

<style scoped>

</style>
